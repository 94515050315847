<template>
  <div>
    <ContactForm />
    <InfoCard />
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import InfoCard from "@/components/InfoCard.vue";

export default {
  components: {
    ContactForm,
    InfoCard
  }
};
</script>

<style></style>
