<template>
  <v-speed-dial
    v-model="fab"
    bottom
    right
    fixed
    direction="top"
    transition="slide-y-transition"
  >
    <template v-slot:activator>
      <v-btn v-model="fab" color="blue darken-2" dark fab>
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-menu
        </v-icon>
      </v-btn>
    </template>
    <v-btn fab :dark="$vuetify.theme.dark" small @click="toggleDark">
      <v-icon>mdi-invert-colors</v-icon>
    </v-btn>
    <v-btn @click="$bus.$emit('openContact')" fab dark small color="indigo">
      <v-icon>mdi-card-account-phone</v-icon>
    </v-btn>
    <v-btn @click="$bus.$emit('openInfo')" fab dark small color="success">
      <v-icon>mdi-information</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  data: () => ({
    fab: false
  }),
  methods: {
    toggleDark() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>

<style></style>
