<template>
  <v-row justify="center">
    <v-col
      cols="12"
      md="6"
      lg="4"
      v-for="o in orgs"
      :key="o.name"
      class="d-flex flex-column"
    >
      <v-card class="flex d-flex flex-column">
        <v-card-title class="mb-1">
          <v-icon :color="o.color" large left>mdi-{{ o.icon }}</v-icon>
          {{ o.name }}
          <v-spacer />
          <v-btn :href="o.link" v-if="'link' in o" icon
            ><v-icon>mdi-arrow-right</v-icon></v-btn
          >
        </v-card-title>
        <v-card-subtitle>{{ o.desc }}</v-card-subtitle>
        <!-- <v-card-actions>
          <v-spacer /><v-btn icon><v-icon>mdi-arrow-right</v-icon></v-btn>
        </v-card-actions> -->
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import orgs from "@/data/orgs.json";

export default {
  data: () => ({
    orgs: orgs
  })
};
</script>

<style></style>
