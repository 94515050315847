<template>
  <div>
    <v-card-title class="justify-center">
      <v-img :src="$vuetify.theme.dark ? logo_dark : logo" max-width="300px" />
    </v-card-title>
    <v-card-title class="text-h4 justify-center">
      Explore All Possible Strategies.
    </v-card-title>
    <v-row>
      <v-spacer />
      <v-card-subtitle class="text-center" style="max-width: 400px;"
        >The world's leading provider of Exotic Matter fusion intelligent
        solutions. Leader of future computing technology and applied XM
        research.</v-card-subtitle
      >
      <v-spacer />
    </v-row>
  </div>
</template>

<script>
import logo from "@/assets/se.svg";
import logo_dark from "@/assets/se_dark.svg";

export default {
  data: () => ({
    logo: logo,
    logo_dark: logo_dark
  })
};
</script>

<style scoped>
* {
  text-align: center;
  text-align-last: center;
}
</style>
