<template>
  <v-app>
    <Background />
    <v-main id="main">
      <v-container>
        <v-row style="height: 100%;" align="center">
          <v-col cols="12">
            <v-card class="pt-12 pb-6">
              <v-row no-gutters>
                <v-col cols="12">
                  <PageHeader />
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header hide-actions>
                        <template v-slot:default="{ open }">
                          <v-row no-gutters>
                            <v-spacer />
                            <v-btn icon outlined large
                              ><v-icon>{{
                                open ? "mdi-arrow-up" : "mdi-arrow-down"
                              }}</v-icon></v-btn
                            >
                            <v-spacer />
                          </v-row>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <Depts />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <Modals />
      <MenuFab />
    </v-main>
  </v-app>
</template>

<script>
import Background from "@/components/Background.vue";
import MenuFab from "@/components/MenuFab.vue";
import PageHeader from "@/components/Header.vue";
import Depts from "@/components/Depts.vue";
import Modals from "@/components/Modals.vue";
import logo from "@/assets/se_dark.svg";
import orgs from "@/data/orgs.json";

export default {
  components: { Background, MenuFab, PageHeader, Depts, Modals },
  data: () => ({
    logo: logo,
    orgs: orgs
  }),
  created() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
    }
  }
};
</script>

<style>
html {
  overflow-y: unset !important;
}
#main .container {
  height: 100%;
}
#main * {
  word-break: keep-all;
  font-family: "Nunito" !important;
}
</style>
