<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Contact Us</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Your first name*"
                hint="Please enter your first name."
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Your last name*"
                hint="Please enter your last name."
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Email*" required></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Organization"
                hint="Your organization."
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                :items="[
                  'Sales',
                  'Network Services',
                  'Cooperation',
                  'Academic',
                  'Others'
                ]"
                label="Topic*"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Whatever you want to tell us..."></v-textarea>
            </v-col>
          </v-row>
        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn color="success" @click="dialog = false">
          <v-icon left>mdi-send</v-icon>
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false
  }),
  created() {
    this.$bus.$on("openContact", () => {
      this.dialog = true;
    });
  }
};
</script>

<style></style>
