<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title>About Us </v-card-title>
      <v-card-title
        ><v-img
          :src="$vuetify.theme.dark ? logo_dark : logo"
          max-width="300px"
        />
      </v-card-title>

      <v-card-text>
        <p>
          <b>Strategic Explorations LTD</b><br />71-75, Shelton Street, London,
          Greater London<br />WC2H 9JQ, UNITED KINGDOM
        </p></v-card-text
      >
      <v-card-text>
        Strategic Explorations LTD is a subsidiary of Hulong Transglobal.
        <br />
        Copyright © 2020 All rights reserved</v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
import logo from "@/assets/se.svg";
import logo_dark from "@/assets/se_dark.svg";

export default {
  data: () => ({
    dialog: false,
    logo: logo,
    logo_dark: logo_dark
  }),
  created() {
    this.$bus.$on("openInfo", () => {
      this.dialog = true;
    });
  }
};
</script>

<style></style>
